import Vue from "vue"
import Vuex from "vuex"

import moment from "moment"
import "moment/locale/it"
// import "moment/locale/en-gb"
moment.locale("it")

import utils from "@/store/utils"
import navigationModule from "@/store/navigation"
import sessionModule from "@/store/session"


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cookiePolicyFlag: false
  },
  mutations: {
    initializeStore(state) {
      if (utils.existsInLS("cookiePolicyFlag")) {

        // load persistent data from local storage
        state.cookiePolicyFlag = utils.loadFromLS("cookiePolicyFlag")
      } else {
        // set persistent data in local storage
        utils.saveToLS("cookiePolicyFlag", state.cookiePolicyFlag)
      }
    },

    setCookiePolicyFlag(state) {
      state.cookiePolicyFlag = true
      utils.saveToLS("cookiePolicyFlag", state.cookiePolicyFlag)
    }
  },
  actions: {
    setCookiePolicyFlag({ commit }) {
      commit("setCookiePolicyFlag")
    }
  },
  modules: {
    navigation: navigationModule,
    session: sessionModule
  }
})