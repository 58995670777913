<script>  
  export default {
    computed: {
      showModal() {
        return !this.$store.state.cookiePolicyFlag
      }
    },
    methods: {
      submit() {
        this.$store.dispatch("setCookiePolicyFlag")
      }
    }
  }
</script>

<template>
  <div class="modal" v-bind:class="{'is-active': showModal}">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body">
        <div class="columns is-mobile">
          <div class="column">
            <label class="title is-4"> {{ $t("title") }} </label>
          </div>
        </div>

        <div class="container block">
          <p>
            {{ $t("text") }}
            
          </p>
        </div>

        <div class="columns is-mobile is-vcentered">
          <div class="column">
          <a class="is-rounded" href="https://google.com"  target="_blank">{{ $t("link") }}</a>
          </div>
          <div class="column is-narrow">
            <button class="button is-info is-rounded" v-on:click="submit">{{ $t("button") }}</button>
          </div>
        </div>
        
      </section>
      
    </div>
  </div>
</template>

<style lang="scss">
#messagesDiv {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  
  bottom: 0px;
  right: 0px;
  margin: auto;

  pointer-events:none;  

  z-index:10;
  display:visible;
}
</style>


<i18n>
{
  "it": {
    "title": "Cookie Policy",
    "text": "TRAC19 utilizza cookie o tecnologie simili come specificato nella Cookie Policy. Clicca su ‘Accetto’ per acconsentire all’utilizzo di tali tecnologie ed utilizzare TRAC19.",
    "link": "Scopri di più",
    "button": "Accetto"
    
  }
}
</i18n>