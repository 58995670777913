<script>  
  export default {
    data: function () {
      return {
        isVisibleModal: false, 
        isVisibleDropdown: false,
        languages: [
          {
            "id": "it",
            "name": "Italiano"
          },
        ]
      }
    },
    computed: {
      currentLanguageName() {
        var langIdx = this.languages.findIndex(elem => elem.id == this.$store.state.session.language)
        return this.languages[langIdx].name
      },
      isBackButtonEnabled() {
        return this.$store.state.navigation.isBackButtonEnabled
      }
    },
    props : ["screenWidth"],
    methods: {
      toggleNavWindow() {
        this.isVisibleModal = !this.isVisibleModal

        // enable or disable page scroll
        if (this.isVisibleModal) {
          document.documentElement.style.overflow = "hidden"
        } else {
          document.documentElement.style.overflow = "auto"  
        }
      },
      toggleNavWindowAndRouteTo(path) {
        this.toggleNavWindow()
        if (path != this.$route.path)
          this.$router.replace({name: path})
      },
      goToPreviousPage() {
        this.$store.dispatch("goToPreviousView")
      }, 
      setLanguage(lang) {
        this.$store.dispatch("setLanguage", lang)
      }
    },
    created() {
      window.addEventListener("resize", this.resizeEventHandler)
    },
    destroyed() {
      window.removeEventListener("resize", this.resizeEventHandler)
    }
  }
</script>

<template>
  <div>
    <div class="navbar is-fixed-top columns is-centered is-vcentered is-marginless is-mobile" id="my-navbar" v-if="screenWidth > 768">
      <div class="column is-8-desktop is-12-tablet">
        <div class="level is-mobile">
          <div class="level-item">
            <a role="button" class="button my-secondary-button is-medium" v-on:click="goToPreviousPage" v-bind:class="{'is-invisible': !isBackButtonEnabled}">
              <span class="icon">
                <i class="fas fa-arrow-left"></i>
              </span>
              <span>{{ $t("back") }}</span>
            </a>
          </div>
          <div class="level-item">
            <div class="level is-mobile">
              <div class="level-item">
                <figure class="image is-32x32">
                  <img :src="require('@/assets/images/logo-64.png')">
                </figure>
              </div>
              <div class="level-item">
                <p class="title">{{$t("appName")}}</p>
              </div>
            </div>
          </div>
          <div class="level-item">
            <div class="level is-mobile" id="my-navOptionsList">
              <div class="level-item mr-5">
                <a class="is-size-5 has-text-weight-semibold" v-on:click="$router.replace({name: 'home'})">
                  {{ $t("homeLink") }}
                </a>
              </div>
              <div class="level-item mr-5">
                <a class="is-size-5 has-text-weight-semibold" v-on:click="$router.replace({name: 'help-faq'})">
                  {{ $t("helpLink") }}
                </a>
              </div>
              <div class="level-item is-hidden">
                <div class="dropdown is-right is-medium" v-on:click="isVisibleDropdown = !isVisibleDropdown" v-bind:class="{'is-active': isVisibleDropdown}">
                  <div class="dropdown-trigger">
                    <button class="button" aria-haspopup="true" aria-controls="dropdown-menu3">
                      <span class="is-size-5"> {{ currentLanguageName }} </span>
                      <span class="icon is-small">
                        <i class="fas fa-angle-down" aria-hidden="true"></i>
                      </span>
                    </button>
                  </div>
                  <div class="dropdown-menu" id="dropdown-menu3" role="menu">
                    <div class="dropdown-content">
                      <a href="#" class="dropdown-item is-size-5"
                        v-for="(lang, index) in languages"
                        v-bind:key="index"
                        v-on:click="setLanguage(lang.id)" >
                        {{ lang.name }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="screenWidth <= 768" class="navbar is-fixed-top columns is-centered is-vcentered is-marginless is-mobile" id="my-navbar">
      <div class="column is-8-desktop is-12-tablet">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <div class="level pl-3 is-mobile is-clickable" v-on:click="$router.replace({name: 'home'})">
                <div class="level-item">
                  <figure class="image is-32x32">
                    <img :src="require('@/assets/images/logo-64.png')">
                  </figure>
                </div>
                <div class="level-item">
                  <p class="title">{{$t("appName")}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <div class="level is-mobile" id="my-navOptionsList">
                <div class="level-item">
                  <a role="button" class="button is-medium" v-on:click="goToPreviousPage" v-bind:class="{'is-invisible': !isBackButtonEnabled}">
                    <span class="icon">
                      <i class="fas fa-arrow-left"></i>
                    </span>
                  </a>
                </div>
                <div class="level-item">
                  <a role="button" class="button is-medium" v-on:click="$router.replace({name: 'help-faq'})">
                    <span class="icon">
                      <i class="fas fa-question"></i>
                    </span>
                  </a>
                </div>
                <div class="level-item is-hidden">
                  <a role="button" class="button is-medium" v-on:click="$router.replace({name: 'help-faq'})">
                    <span class="icon">
                      <i class="fas fa-language"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "it": {
    "appName": "TRAC19",
    "helpLink": "Aiuto",
    "homeLink": "Home",
    "back": "Indietro"
  }
}
</i18n>

<style>

  .borderless-button {
    border: none;
  }

  #my-navbar {
    box-shadow: 0 5px 20px rgba(0,0,0,.1); 
    height: 76px;
    z-index: 5;
  }

  @media screen and (max-width: 768px) {
  }

  @media screen and (min-width: 769px) {
  }
</style>