function evaluateCondition(questions, answerSet, cond) {
  if ('question' in cond) {
    var answer = answerSet[cond.question]
    if (answer === undefined) {
      return cond.unasked ^ cond.negate
    }
    if (answer === null || typeof answer === 'string') {
      return (answer === cond.answer) ^ cond.negate
    } else {
      return answer.includes(cond.answer) ^ cond.negate
    }
  } else if (cond.operator === 'all') {
    for (var subcond of cond.conditions) {
      if (!evaluateCondition(questions, answerSet, subcond)) {
        return cond.negate
      }
    }
    return !cond.negate
  } else if (cond.operator === 'any') {
    for (subcond of cond.conditions) {
      if (evaluateCondition(questions, answerSet, subcond)) {
        return !cond.negate
      }
    }
    return cond.negate
  }
}

export function extractAnswerSet(questions, answerList) {
  const set = {}
  for (var ans of answerList) {
    set[questions[ans.index].key] = ans.value
  }
  return set
}

export function nextQuestionIndex(questions, answerList, latest = -1) {
  var n = latest + 1
  var answerSet = null
  for (; n < questions.length; n++) {
    const question = questions[n]
    if (question.condition === null) {
      return n
    }
    if (answerSet === null) {
      answerSet = extractAnswerSet(questions, answerList)
    }
    if (evaluateCondition(questions, answerSet, question.condition)) {
      return n
    }
  }
  return null
}

export function extractQuestionAnswerPairs(questions, answerList) {
  /*
   *  Given the questions structure and a list of answers keys, extract pairs 
   *  of (question text, answer label).  
   */
  const set = []
  for (const ans of answerList) {
    var options = questions[ans.index].answer.options
    set.push({
      "question": questions[ans.index].prompt.it,
      "answer": options[options.findIndex(elem => elem.key == ans.value)].label.it
    })
  }
  return set
}