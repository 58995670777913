<template>
  <div class="section" id="messagesDiv">
    <div class="columns is-centered is-vcentered">
      <div class="column is-narrow">
        <MyMessage class="has-text-centered"
          v-for="msg in openMessages"
          v-bind:key="msg.id"
          v-bind:message="msg.message"
          v-bind:type="msg.type" 
          v-bind:id="msg.id"
          v-on:remove="removeMessage"/>
      </div> 
    </div>
  </div>
</template>

<style lang="scss">
#messagesDiv {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  
  bottom: 0px;
  right: 0px;
  margin: auto;

  pointer-events:none;  

  z-index:10;
  display:visible;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;  
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    flex-direction: column;
    align-items: center; 
  }
  @media screen and (min-width: 1024px) {
    flex-direction: column-reverse;
    align-items: flex-end;  
  }

  .my-message {
    @media screen and (max-width: 768px) {
      width: 100%;  
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      width: 50%;
    }
    @media screen and (min-width: 1024px) {
      width: 25%;
    }

  }
}
</style>

<script>
  import EventBus from "@/eventbus"
  import MyMessage from "@/components/MyMessage"
  
  export default {
    data: function() {
      return {
        id: 0,
        openMessages: []
      }
    }, 
    mounted() {
      EventBus.$on("successMessage", (msg) => {
        this.openMessages.push({type:"is-success", message:msg, id:this.id++});
      }),
      EventBus.$on("warningMessage", (msg) => {
        this.openMessages.push({type:"is-warning", message:msg, id:this.id++});
      }),
      EventBus.$on("errorMessage", (msg) => {
        this.openMessages.push({type:"is-danger", message:msg, id:this.id++});
      }),

      EventBus.$on("connectionErrorMessage", () => {
        this.openMessages.push({type:"is-danger", message: "Impossibile comunicare con il server", id:this.id++});
      }),
      EventBus.$on("responseErrorMessage", () => {
        this.openMessages.push({type:"is-danger", message: "Operazione non riuscita", id:this.id++});
      }),
      EventBus.$on("genericErrorMessage", () => {
        this.openMessages.push({type:"is-danger", message: "Operazione fallita", id:this.id++});
      })
      
    },
    components: {
      MyMessage
    },
    methods: {
      removeMessage(id) {
        const idx = this.openMessages.findIndex(f => f.id === id);
        if (idx != -1)
          this.openMessages.splice(idx, 1);   
      }
    }
  }
</script>