import moment from "moment"

function parseJson(str) {
  try {
    return JSON.parse(str)
  } catch (e) {
    return null
  }
}

export default {
  // ##################### local storage utilities #######################
  loadFromLS(name) {
    return parseJson(localStorage.getItem(name))
  },
  saveToLS(name, item) {
    localStorage.setItem(name, JSON.stringify(item))
  },
  existsInLS(name) {
    return !(localStorage.getItem(name) === null)
  },
  deleteFromLS(name) {
    localStorage.removeItem(name)
  },

  // ##################### session storage utilities ######################
  loadFromSS(name) {
    return parseJson(sessionStorage.getItem(name))
  },
  saveToSS(name, item) {
    sessionStorage.setItem(name, JSON.stringify(item))
  },
  existsInSS(name) {
    return !(sessionStorage.getItem(name) === null)
  },

  // ######################## cookie utilities ##############################

  saveCookie(name, value, seconds) {
    document.cookie = name + "=" + JSON.stringify(value) + "; expires=" + moment().add(seconds, "seconds").toDate() + ";"
  },
  loadCookie(name) {
    return parseJson(document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop())
  },
  deleteCookie(name) {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC"
  },
  existsCookie(name) {
    return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() !== undefined
  }
}
