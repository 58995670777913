<template>
  <article class="message mb-1" v-bind:class="type">
    <div class="message-body">
      <button class="delete is-pulled-right" v-on:click="remove()"></button>
      <p class="pr-5">
        {{ message }}
      </p>
    </div>
  </article>
</template>

<script>
  export default {
    methods: {
      remove() {
        this.$emit("remove", this.id)  
      }
    },
    props: {
      message: String,
      type: String,
      id: Number
    },
    mounted() {
      setTimeout(() => {
        this.remove()
      }, 2000)
    }    
  }
</script>