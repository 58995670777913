const NAVIGATION_HISTORY_SIZE = 20

export default {
  state: () => ({
    isBackButtonEnabled: false,
    backButtonLogic: null,
    navigationHistory: [],
  }),
  getters: {
    previousPage(state) {
      if (state.navigationHistory.length > 1)
        return state.navigationHistory[state.navigationHistory.length - 2]
      return null
    },
    currentPage(state) {
      if (state.navigationHistory.length >= 1)
        return state.navigationHistory[state.navigationHistory.length - 1]
      return null
    }
  },
  mutations: {
    disableBackButton(state) {
      state.isBackButtonEnabled = false
    },
    enableBackButton(state) {
      state.isBackButtonEnabled = true
    },
    setBackLogic(state, logic) {
      state.isBackButtonEnabled = true
      state.backButtonLogic = logic
    },
    fireBackButtomLogic(state) {
      if (state.isBackButtonEnabled) {
        state.backButtonLogic()
      }
    },

    pushPage(state, pageName) {
      if (pageName != state.navigationHistory[state.navigationHistory.length - 1]) {
        state.navigationHistory.push(pageName)
        if (state.navigationHistory.length > NAVIGATION_HISTORY_SIZE) {
          state.navigationHistory.splice(0, state.navigationHistory.length - NAVIGATION_HISTORY_SIZE)
        }
      }
    },
    popPage(state, pageName) {
      state.navigationHistory.pop(pageName)
    },
    cleanNavigationHistory(state) {
      state.navigationHistory.splice(0, state.navigationHistory.length)
    }

  },
  actions: {
    disableBackButton({ commit }) {
      commit("disableBackButton")
    },
    enableBackButton({ commit }) {
      commit("enableBackButton")
    },
    setBackLogic({ commit }, logic) {
      commit("setBackLogic", logic)
    },

    goToPreviousView({ commit }) {
      commit("fireBackButtomLogic")
    },

    pushPage({ commit }, pageName) {
      commit("pushPage", pageName)
    },
    popPage({ commit }, pageName) {
      commit("popPage", pageName)
    },
    cleanNavigationHistory({ commit }) {
      commit("cleanNavigationHistory")

    }
  }
}