import axios from "axios"

const backendUrl = process.env.VUE_APP_BACKEND

axios.defaults.withCredentials = true

const api = {

  async getStructuresList() {
    return axios.get(`${backendUrl}/structurelist`)
  },
  async queryStructures(query) {
    return axios.get(`${backendUrl}/structures`, { params: { q: query } })
  },
  async getMails() {
    return axios.get(`${backendUrl}/mailact`)
  },
  async newMailRequest(mailAddress, captchaResponse) {
    return axios.post(`${backendUrl}/mailactreq`, { address: mailAddress, consents: [] }, { headers: { "x-captcha-response": captchaResponse } })
  },
  async sendMailActivationToken(token) {
    return axios.post(`${backendUrl}/mailact`, { token: token })
  },
  async getQuestionnaire(structures) {
    var params = new URLSearchParams()
    for (var stid of structures)
      params.append("st", stid)
    return axios.get(`${backendUrl}/questions`, { params: params }, { withCredentials: false })
  },
  async submitQuestionnaire(params, captchaResponse) {
    return axios.post(`${backendUrl}/form`, params, { headers: { "x-captcha-response": captchaResponse } })
  },
  // getQrCodeURL(key) {
  //   return `${backendUrl}/formqr?key=` + key
  // },
  // async resendEmail(params) {
  //   return axios.post(`${backendUrl}/formmailreq`, params)
  // },
  // async changeStructuresConsents(params) {
  //   return axios.post(`${backendUrl}/formrecipients`, params)
  // },
  async deleteMailAddress(params) {
    return axios.delete(`${backendUrl}/mailact`, { data: params })
  },
  async getQuestionnaireKeyCode(params) {
    return axios.post(`${backendUrl}/tempcodereq`, params)
  },
  async revokeQuestionnaireAccess(params) {
    return axios.delete(`${backendUrl}/form`, { data: params })
  },


}

export default api
