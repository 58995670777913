import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

import i18n from '@/i18n'

import '@/assets/scss/app.scss'

import 'bulma'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

Vue.use(Buefy, {
  defaultIconPack: 'fa'
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  beforeCreate() { this.$store.commit('initializeStore') },
  render: h => h(App)
}).$mount('#app')
