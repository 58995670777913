import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
  {
    path: "*",
    component: () =>
      import(/* webpackChunkName: "PageNotFound" */
        "@/views/PageNotFound.vue")
  },
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "Home" */
        "@/views/Home.vue")
  },
  {
    path: "/help/faq",
    name: "help-faq",
    component: () =>
      import(/* webpackChunkName: "HelpFaq" */
        "@/views/HelpFaq.vue")
  },
  {
    path: "/mail",
    name: "mail",
    component: () =>
      import(/* webpackChunkName: "Mail" */
        "@/views/Mail.vue")
  },
  {
    path: "/mail/add",
    name: "mail-add",
    component: () =>
      import(/* webpackChunkName: "MailAdd" */
        "@/views/MailAdd.vue"),
  },
  {
    path: "/questionnaire",
    name: "questionnaire",
    component: () =>
      import(/* webpackChunkName: "Questionnaire" */
        "@/views/Questionnaire.vue")
  },
  {
    path: "/questionnaire/key",
    name: "suestionnnaire-key",
    component: () =>
      import(/* webpackChunkName: "QuestionnaireKey" */
        "@/views/QuestionnaireKey.vue")
  },
  {
    path: "/activatemail",
    name: "mail-activation",
    component: () =>
      import(/* webpackChunkName: "MailActivation" */
        "@/views/MailActivation.vue")
  },
  {
    path: "/questionnaire/revoke",
    name: "questionnaire-revoke-access",
    component: () =>
      import(/* webpackChunkName: "RevokeAccessConsent" */
        "@/views/RevokeAccessConsent.vue")
  }
]

const router = new VueRouter({
  mode: "history",
  routes
})

export default router
