import utils from "@/store/utils"
import api from "@/api"

import { nextQuestionIndex } from "@/questionnaire"

var SESSION_SUSPENSION_MAX_SECONDS = 3600

export default {
  state: () => ({
    questState: "",
    questMail: "",
    questAnalytics: {},
    questStructures: [],
    questStructuresConsents: [],
    questModel: "",
    questQuestions: [],
    questAnswers: [],
    questCurrentIdx: -1,
    questNotes: "",
    questCaptchaCode: "",
    questNotices: null,

    language: "it",
  }),
  mutations: {
    loadSessionAndDeleteLS(state) {
      state.questMail = utils.loadCookie("questMail")
      state.questStructures = utils.loadCookie("questStructures")
      state.questStructuresConsents = utils.loadCookie("questStructuresConsents")

      utils.deleteCookie("questStructures")
      utils.deleteCookie("questStructuresConsents")
      utils.deleteCookie("questMail")
    },

    saveSessionAndDeleteState(state) {
      utils.saveCookie("questMail", state.questMail, SESSION_SUSPENSION_MAX_SECONDS)
      utils.saveCookie("questStructures", state.questStructures, SESSION_SUSPENSION_MAX_SECONDS)
      utils.saveCookie("questStructuresConsents", state.questStructuresConsents, SESSION_SUSPENSION_MAX_SECONDS)

      // state.questMail = ""
      state.questStructures = []
      state.questStructuresConsents = []
    },

    setLanguage(state, lang) {
      state.language = lang
    },

    setQuestStructures(state, structures) {
      state.questStructures = structures
    },
    setQuestStructuresConsents(state, consents) {
      state.questStructuresConsents = consents
    },

    setQuestMail(state, address) {
      state.questMail = address
    },

    setQuestQuestions(state, data) {
      state.questModel = data[0]
      state.questQuestions = data[1]
      state.questCurrentIdx = -1
      state.questAnswers = []
    },
    jumpToNextQuestion(state) {
      state.questCurrentIdx = nextQuestionIndex(state.questQuestions, state.questAnswers, state.questCurrentIdx)
    },
    backToPreviousQuestion(state) {
      state.questCurrentIdx = state.questAnswers.pop().index
    },
    setQuestionAnswer(state, value) {
      state.questAnswers.push({ index: state.questCurrentIdx, value: value })
    },
    setQuestNotes(state, value) {
      state.questNotes = value
    },


    setQuestionnaireState(state, newState) {
      state.questState = newState
    },


    setQuestionnaireCaptcha(state, code) {
      state.questCaptchaCode = code
    },

    setQuestionnaireNotices(state, data) {
      state.questNotices = data
    },

    resetQuestionnaireState(state) {
      state.questMail = ""
      state.questAnalytics = {}
      state.questStructures = []
      state.questStructuresConsents = []
      state.questModel = ""
      state.questNotes = ""
      state.questQuestions = []
      state.questAnswers = []
      state.questCurrentIdx = -1
      state.questCaptchaCode = ""
      state.questNotices = null
    },

    partiallyResetQuestionnaireState(state) {
      state.questAnalytics = {}
      state.questModel = ""
      state.questNotes = ""
      state.questQuestions = []
      state.questAnswers = []
      state.questCurrentIdx = -1
      state.questCaptchaCode = ""
      state.questNotices = null
    },
  },
  actions: {
    setLanguage({ commit }, lang) {
      commit("setLanguage", lang)
    },

    submitQuestMail({ commit }, address) {
      commit("setQuestMail", address)
    },

    submitQuestStructures({ commit }, structures) {
      commit("setQuestStructures", structures)
    },

    submitQuestStructuresConsents({ commit }, consents) {
      commit("setQuestStructuresConsents", consents)
    },

    requestQuestionnaire({ state, commit }) {
      return new Promise((resolve, reject) => {
        try {
          api.getQuestionnaire(state.session.questStructures.map(elem => elem.id))
            .then(response => {
              commit("setQuestQuestions", [response.data.model, response.data.questions])
              commit("jumpToNextQuestion")
              resolve()
            })
            .catch(() => {
              reject()
            })
        } catch (error) {
          reject()
        }
      })
    },
    setQuestQuestions({ commit }, data) {
      commit("setQuestQuestions", data)
    },
    jumpToNextQuestion({ commit }) {
      commit("jumpToNextQuestion")
    },
    backToPreviousQuestion({ commit }) {
      commit("backToPreviousQuestion")
    },
    setQuestionAnswer({ commit }, value) {
      commit("setQuestionAnswer", value)
    },
    setNotes({ commit }, notes) {
      commit("setQuestNotes", notes)
    },

    resetQuestionnaireState({ commit }) {
      commit("resetQuestionnaireState")
    },

    partiallyResetQuestionnaireState({ commit }) {
      commit("partiallyResetQuestionnaireState")
    },

    saveSessionAndDeleteState({ commit }) {
      commit("saveSessionAndDeleteState")
    },

    loadSessionAndDeleteLS({ commit }) {
      commit("loadSessionAndDeleteLS")
    },

    setQuestionnaireState({ commit }, newState) {
      commit("setQuestionnaireState", newState)
    },

    setQuestionnaireCaptcha({ commit }, code) {
      commit("setQuestionnaireCaptcha", code)
    },

    setQuestionnaireNotices({ commit }, data) {
      commit("setQuestionnaireNotices", data)
    }


  }
}