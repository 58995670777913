<script>
import MyNavbar from "@/components/MyNavbar"
import MyMessages from "@/components/MyMessages"
import MyCookieModal from "@/components/MyCookieModal"

export default {
  data: function () {
    return { 
      screenWidth: window.innerWidth,
    }
  },
  methods: {
    resizeEventHandler() {
      // handle web page scrolling
      this.screenWidth = window.innerWidth
      if (this.screenWidth > 768 && this.isVisibleModal) {
        this.toggleNavWindow()
      }
    }
  },
  components: {
    MyNavbar,
    MyMessages,
    MyCookieModal,
  },
  created() {
    window.addEventListener("resize", this.resizeEventHandler)
    window.addEventListener("resize", this.preventReload)
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeEventHandler)
    window.addEventListener("resize", this.preventReload)
  }
}
</script>

<template>
  <div id="app">
    <MyNavbar v-bind:screenWidth="this.screenWidth"/>
    
    <router-view v-bind:screenWidth="this.screenWidth"/>

    <MyMessages />
    <MyCookieModal />

  </div>
</template>

<style>

</style>
